export const values = [
  [
    "Maureen Burke",
    "Glasgow North East",
    "",
    "Jonathan",
    "TRUE",
    "",
    "TRUE",
    "james",
    "SNP",
    "4095",
    "Yes. Councillor for Glasgow North East",
    "Maureen Burke was picked to contest Glasgow North East at the next election. She is a councillor on Glasgow City Council, representing North East ward.\n\nBorn & bred & lives in Easterhouse in the seat. First worked as packer in tie-factory. Worked for Margaret Curran when she was MSP, MP & Shadow Scottish secretary. - https://twitter.com/tomorrowsmps/status/1651337216784756739",
    "https://twitter.com/CllrBurke",
    "https://www.facebook.com/profile.php?id=100047615836936",
    "",
    "",
    "Councillor Glasgow City Council, North East Ward - <https://labourlist.org/2023/08/our-rolling-list-of-labour-parliamentary-candidate-selections/>",
    "(possibly ex) Chair of the Seven Lochs Partnership. \nDirector - Glasgow East Women's Aid - <https://labourlist.org/2023/08/our-rolling-list-of-labour-parliamentary-candidate-selections/>",
    "Provan Hall Community Management Trust (a 15th Century historical building in Glasgow) - <https://www.glasgow.gov.uk/councillorsandcommittees/agenda.asp?meetingid=15465>",
    "Director - Glasgow East Women's Aid (resigned)|\nDirector - Glasgow East Arts Company (resigned)|\nDirector - Culture and Sport Glasgow CIC (resigned)|\nDirector - Culture and Sport Glasgow (resigned)|\nDirector - Glasgow East Arts Company Ltd (resigned)|\nDirector - Jobs and Business Glasgow (resigned)|\nDirector - Clude Gateway URC (resigned)|\nDirector - Scottish Events Campus (resigned) - <https://find-and-update.company-information.service.gov.uk/officers/PQhWYXthUIbiNIdpZxTXs0hltjM/appointments>",
    "Supported Glasgow's Climate Week in 2016. Was Chair of the Seven Lochs Partnership. She said :\"We all have a part to play in tackling the issues of climate change. The Seven Lochs Wetland Park project is a fitting example of what we are doing to make Glasgow a more beautiful, liveable and sustainable city. This will be the jewel in the crown of the region's green spaces, and will bring a host of benefits for local people and visitors alike.\" - <https://glasgow.gov.uk/article/20096/Time-for-action-as-Scotlands-first-national-Climate-Week-gets-underway>",
    "Positive",
    'Anti-detention centres (from 2015\n"Council therefore calls on the Chief Executive to write to the relevant UK Government to express our strong resolve that Dungavel, and all other UK detention centres, should be closed in favour of a community-based model of assessment for those seeking asylum in Scotland and the wider United Kingdom." - <https://www.glasgow.gov.uk/councillorsandcommittees/agenda.asp?meetingid=13813>',
    "Positive",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "In role as councillor, suggested approving the application of a conversion of townhouses to tourist short stay flats - <https://servicedapartmentnews.com/news/industry-news/glasgow-townhouse-conversion/> ",
    "",
    "",
    "",
    "maureen.burke@glasgow.gov.uk",
    "",
    "Embroiled in a conflict of interest scandal - <https://www.heraldscotland.com/news/15255963.secret-recording-leads-call-glasgow-labour-councillors-suspended/>",
    "",
    "TRUE",
    "James",
    "TRUE",
    "Andy",
    "",
    "https://www.glasgow.gov.uk/media/image/t/d/Maureen_Burke.JPG",
    "83%",
    "<https://www.electoralcalculus.co.uk/fcgi-bin/calcwork23.py?seat=Glasgow+North+East>",
  ],
  [
    "Zubir Ahmed",
    "Glasgow South West",
    "",
    "",
    "FALSE",
    "",
    "TRUE",
    "",
    "",
    "",
    "",
    "Zubir Ahmed has been selected as the candidate for… 2021, coming in second behind the SNP candidate.",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
  ],
  [
    "Frank McNally",
    "Coatbridge and Bellshill (formerly Coatbridge, Chryston and Bellshill)",
    "",
    "Jonathan",
    "TRUE",
    "",
    "TRUE",
    "",
    "SNP",
    "5,261",
    "Yes. Councillor for Mossend and Holytown (North Lanarkshire)",
    "Frank McNally has been selected as Scottish Labour…nce 2012, representing Mossend and Holytown ward.",
    "https://twitter.com/FJMcNally",
    "https://www.facebook.com/frank.mcnally.52",
    "https://www.linkedin.com/in/frank-mcnally-6320bb139",
    "",
    "Councillor for North Lanarkshire since 2012",
    "Food 365 - campaign to deliver free school meals",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Made a passing reference to NHS cuts in this campaign video",
    "Neutral",
    "",
    "",
    "Weighed in against a strike by teachers in a school with blue water coming out of the taps",
    "Negative",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "90%",
  ],
  [
    "Roisin McKenna Favier",
    "Glasgow Central [to be abolished]",
    "",
    "Jonathan",
    "TRUE",
    "",
    "TRUE",
    "Sara",
    "",
    "",
    "",
    "Roisin McKenna was selected as the next candidate …al-year medical student at University of Glasgow.",
    "https://twitter.com/Roisin_McKenna",
    "",
    "https://www.linkedin.com/in/roisin-mckenna-favier-423774151/?originalSubdomain=uk",
    "",
    "Final-year Medical Student (according to LabourList)",
    "Member of a number of student societies including …icial, Medical Student Ambassador for Glasgow Uni",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Ran the Great North Run for MS Society",
    "Neutral",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "92%",
  ],
  [
    "Martin Rhodes",
    "Glasgow North",
    "",
    "Jonathan",
    "TRUE",
    "",
    "TRUE",
    "Sara",
    "SNP",
    "6,670",
    "",
    "Martin Rhodes has been picked as the candidate for…uncil, representing Partick East/Kelvindale ward.",
    "https://twitter.com/MartinRhodes21",
    "https://www.facebook.com/MartinRhodesGlasgow/",
    "https://www.linkedin.com/in/martin-rhodes-645385188/?originalSubdomain=uk",
    "",
    "Chief Executive of Scottish Fair Trade Forum (appears to be now retired)",
    "",
    "",
    "",
    "Has been involved in Fair Trade campaigning for a … about trade justice and climate justice - Source",
    "Positive",
    "",
    "",
    "Elected honourary member of LGBT+ Labour Scotland in February 2020.",
    "Positive",
    "",
    "",
    "Shared petition for 75th anniversary of NHS on Facebook ",
    "Positive",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "93%",
  ],
  [
    "Michael Shanks MP",
    "Rutherglen and Hamilton West",
    "",
    "",
    "FALSE",
    "",
    "TRUE",
    "",
    "",
    "",
    "",
    "Winner of byelection 10.23 - CURRENT MP. Shanks is…ction to Westminster and the Scottish parliament.",
    "https://twitter.com/mgshanks?lang=en",
    "There is this Michael Shanks who also lives in Gla…/www.facebook.com/michael.shanks.129?locale=en_GB",
    "",
    "",
    "Teacher. This website says he was Head of Policy a…ly 35 - seems to have had a large number of jobs)",
    "",
    "Board member of Epilepsy Scotland\nBoard member of …d active member of St Mary's Episcopal Cathedral)",
    "",
    "",
    "",
    'Resigned from the labour party over its Brexit sta… for now / "dismissing the impact on EU families"',
    "Neutral",
    "Support for 'demedicalisation' (self-ID) for trans people",
    "Positive",
    "",
    "",
    "",
    "",
    "Said that he would scrap the two-child benefit cap ('a heinous policy') and bedroom tax ",
    "Positive",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "94%",
  ],
  [
    "John Grady",
    "Glasgow East",
    "",
    "Jonathan",
    "TRUE",
    "",
    "TRUE",
    "",
    "SNP",
    "6,193",
    "",
    "John Grady was chosen as the next candidate for Gl… partner at the law firm Shepherd and Wedderburn.",
    "https://twitter.com/johnadgrady?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor",
    "Personal twitter\nLabour twitter",
    "https://www.linkedin.com/in/john-grady-a1757031/?originalSubdomain=uk",
    "",
    "Partner at Shepherd and Wedderburn. Worked there f…al law, especially competition and price controls",
    "",
    "",
    "",
    "In his role as lawyer, he was positive about progr…d for local prosperity to flow from new projects)",
    "Positive",
    "",
    "",
    "",
    "",
    "Some (mildly) positive union words",
    "Positive",
    "Lots of tweets about the decline in quality of local services, especially in the NHS (e.g. here)",
    "Positive",
    "",
    "",
    "",
    "",
    "His interview about energy transition doesn't incl…e need to ensure that investors see a good return",
    "Neutral",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "95%",
  ],
  [
    "Gordon McKee",
    "Glasgow South",
    "",
    "Jonathan",
    "TRUE",
    "",
    "TRUE",
    "Sara",
    "SNP",
    "9,103",
    "",
    "Gordon McKee was picked as the next candidate for … adviser to Shadow Scotland Secretary Ian Murray.",
    "https://twitter.com/gordonmckee_",
    "https://www.facebook.com/gordon.mckee.73",
    "",
    "https://www.instagram.com/gordon.mckee/ (private account)",
    "McKee is a political adviser to Shadow Scotland Secretary Ian Murray.",
    "",
    "",
    "None",
    "Visit to EMEC (Eureopean Marine Energy Centre) 'A …itter.com/GordonMcKee_/status/1521399496558657536",
    "Positive",
    "Nothing found",
    "",
    "Nothing found",
    "",
    "Nothing found",
    "",
    "Nothing found",
    "",
    "Nothing found",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "96%",
  ],
];
